.play-store-logo {
    transition: 0.1s;
}

.play-store-logo:hover {
    opacity: .8;
    transition: 0.1s;
}

@media screen and (max-width: 991px) {
    .play-store-logo,
    .mercado-pago-logo {
        width: 20% !important;
    }
}

@media screen and (max-width: 768px) {
    .play-store-logo,
    .mercado-pago-logo {
        width: 35% !important;
    }
}