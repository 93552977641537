.message-payments-wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left: 35px;
}

.message-payments-wrapper .payments-icon {
    max-width: 20px;
    margin-top: 2px;
}

.message-payments-information {
    font-size: .9rem;
    margin-bottom: 0;
}

.payments-icon {
    max-width: 20px;
    width: 100%;
    position: absolute;
    top: 19px;
    left: 16px;
}

@media screen and (max-width: 1200px) {
    .requests-card-toasts-wrapper {
        flex-direction: column !important;
    }
}