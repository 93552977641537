.errorMessageInput {
    font-size: 11px;
    color: #f64c40;
    font-weight: 500;
    margin-top: 5px;
}

.currencyInput {
    display: flex;
    align-items: center;
    width: 2.5em;
    height: 1.5em;
    height: 1.6em;
    width: 3em;
    border-color: gray;
    margin-right: 0.5rem;
}

.userlist-avatar {
    padding-left: 10px;
    padding-right: 10px;
}

.user-listgroup {
    margin-bottom: 10px;
}

.user-listgroup:hover {
    cursor: pointer;
    background-color: azure;
}
