.video-chat-section {
    height: 100vh;
    position: relative;
}

.grid-container {
    position: relative;
    height: 82vh;
    margin-bottom: 16px;
}

.grid-item {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ccc;
    border-radius: 12px;
    display: grid;
}

.video-container {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: black;
    overflow: auto;
}

.circulo-usuario {
    align-self: center;
    justify-self: center;
}

.user-info {
    margin: 10px;
    align-self: end;
    z-index: 7;
    position: absolute;
    display: flex;
    align-items: center;
    gap: 6px;
}

.user-1 {
    /* Estilo para o usuário 1 */
    bottom: 0; /* Posicionamento no canto inferior */
    right: 0; /* Posicionamento no canto direito */
    width: calc(22vh * 16 / 9); /* Largura do usuário 1 (16:9) */
    max-height: 200px;
    z-index: 6;
    margin-right: 6px;
    margin-bottom: 6px;
}

.user-1 .user-info {
    color: white;
    background-color: #404040;
    padding: 6px;
    border-radius: 6px;
}

.user-2 .user-info {
    background-color: #404040;
    padding: 6px;
    border-radius: 6px;
    color: white;
}

.user-2 {
    /* Estilo para o usuário 2 ocupar toda a tela */
    width: 100%;
}

.videochat {
    height: 90vh;
    padding: 20px;
}

.videochat-container {
    height: 80vh;
}
.videochat-actions {
    display: flex;
    margin: 16px;
    justify-content: space-between;
}

.circulo-usuario {
    height: 60px;
    width: 60px;
    border-radius: 50%;
}

.avatar {
    align-self: center;
    justify-self: center;
}

.videochat-buttons {
    display: flex;
    gap: 26px;
    justify-content: center;
    align-self: center;
}

.videochat-actions svg {
    font-size: 36px;
    cursor: pointer;
    transition: transform 0.2s;
}

.videochat-actions svg:hover {
    transform: translateY(-5px) rotate(5deg);
}

.videochat-footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.camera-inactive {
    color: red;
}

.mic-inactive {
    color: red;
}

.camera-active {
    color: #00ccff;
}

.mic-active {
    color: #00ccff;
}

.audio-active {
    border: 2px solid blue;
}
