.skeleton-loader:empty {
    display: block;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
    ),
    lightgray;
    background-repeat: no-repeat;
    background-size: 200px 100%;
    animation: shine 1s infinite;
}

@keyframes shine {
    to {
        background-position: 100% 0;
    }
}

.post-wrapper-skeleton {
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 0.5rem;
}

.post-top-person-wrapper-skeleton {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 16px;
}

.post-img-person-skeleton {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: lightgray;
}

.post-name-text-skeleton {
    width: 100px;
    height: 10px;
    margin-bottom: 8px;
    background: lightgray;
    border-radius: 3px;
}

.post-skills-text-skeleton {
    width: 70px;
    height: 10px;
    background: lightgray;
    border-radius: 3px;
}

.post-bottom-person-wrapper-skeleton {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.post-description-wrapper-skeleton {
    width: 100%;
}

.post-description-text-skeleton {
    width: 80%;
    height: 10px;
    background: lightgray;
    border-radius: 3px;
}

.post-image-skeleton {
    width: 100%;
    height: 200px;
    background: lightgray;
    border-radius: 7px;
}