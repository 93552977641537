.edit-comment .dropdown .btn-icon.btn.btn-ghost.btn-sm.rounded-circle {
    width: 22px !important;
    height: 22px !important;
}

.see-more-comments {
    width: fit-content !important;
    cursor: pointer;
}

.see-more-comments:hover {
    text-decoration: underline;
}

.post-owner-link:hover .post-author-name-text {
    text-decoration: underline;
    text-decoration-color: #1e293b;
    color: #1e293b;
    opacity: .8;
}

.post-image-container,
.post-image-wrapper,
.post-one-image-wrapper,
.post-two-image-wrapper,
.post-three-image-wrapper-triple,
.post-four-image-wrapper,
.post-four-more-image-wrapper {
    width: 100%;
    cursor: pointer;
}

.post-image {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.modal-view-single-post-description {
    max-height: 155px;
    overflow-y: auto;
}

.modal-view-single-post-description::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #f4f4f4;
}
.modal-view-single-post-description::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px;
    background: #f4f4f4;
}
.modal-view-single-post-description::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #d6dbe1;
}

.modal-view-single-post-images-container .carousel-item-image {
    max-width: 100% !important;
    max-height: 100% !important;
}

@media screen and (max-width: 768px) {
    .modal-view-single-post-description-text {
        font-size: 14px;
    }
}

/* Inicio post com 1 imagens */
.post-one-image-wrapper {
    max-height: 650px;
    overflow: hidden;
}
/* Fim post com 1 imagens */


/* Inicio post com 2 imagens */
.post-two-image-wrapper {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.post-two-image-wrapper .post-image {
    max-height: 410px;
    object-fit: fill;
}
/* Fim post com 2 imagens */


/* Inicio post com 3 imagens */
.post-three-image-wrapper-triple {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.post-three-second-image-wrapper {
    display: flex;
    gap: 3px;
}

.post-three-second-image-wrapper .post-image {
    width: 50%;
    max-height: 280px;
}
/* Fim post com 3 imagens */


/* Inicio post com 4 imagens */
.post-four-image-wrapper {
    display: flex;
    flex-direction: row;
    gap: 3px;
}

.post-four-first-image-wrapper {
    width: 70%;
}

.post-four-second-image-wrapper {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 3px;
}
/* Fim post com 4 imagens */


/* Inicio post com mais de 4 imagens */
.post-four-more-image-wrapper {
    display: flex;
    flex-direction: row;
    gap: 3px;
}

.post-four-more-first-image-wrapper {
    width: 70%;
}

.post-four-more-second-image-wrapper {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.post-four-more-image-length {
    position: relative;
}

.post-four-background-image-length {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000000a6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.post-four-text-image-length {
    color: #fff;
    font-size: 35px;
}

@media screen and (max-width: 768px) {
    .post-four-text-image-length {
        font-size: 25px;
    }
}
/* Fim post com mais de 4 imagens */
