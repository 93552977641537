.icone {
    width: 300px;
}

.home-cards-card-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
}

.home-cards-card-wrapper {
    width: 100%;
    padding: 25px;
    border-radius: 9px;
    background-color: white;
}

.home-cads-icon {
    max-width: 70px;
    width: 100%;
}

.home-cards-title {
    font-weight: bold;
    font-size: 18px;
    margin-top: 20px;
}

.home-cards-description {
    line-height: 26px;
    font-size: 16px;
}

@media screen and (max-width: 768px) {
    .container-home-cards-mobile {
        padding-right: 0;
    }

    .home-cards-card-container {
        display: flex;
        flex-wrap: nowrap;
        overflow: scroll;
        padding-right: 10px;
        padding-bottom: 20px;
        grid-gap: 15px;
    }

    .home-cards-card-wrapper {
        min-width: 300px;
    }

    .home-cards-title {
        font-size: 19px;
    }

    .home-cards-description {
        font-size: 14px;
    }

    .home-cads-icon {
        max-width: 65px;
    }
}

.home-page-certainty-container {
    background-color: #fff;
    position: relative;
    justify-content: space-around;
}

.first-circle {
    width: 160px;
    height: 160px;
    background-color: #f1f5f9;
    border-radius: 50%;
    position: absolute;
    left: 100px;
    top: 0px;
}

.second-circle {
    width: 50px;
    height: 50px;
    background-color: #f1f5f9;
    border-radius: 50%;
    position: absolute;
    left: 80px;
    top: -20px;
}

.home-page-description-certainty-wrapper {
    max-width: 395px;
    position: relative;
    margin-bottom: 130px;
}

.home-page-title-certainty-text {
    font-size: 35px;
    font-weight: bold;
    color: #383838;
}

.home-page-description-certainty-text {
    font-size: 20px;
    line-height: 26px;
}

.home-page-image-certainty-wrapper {
    width: fit-content;
}

.home-page-image-certainty {
    max-width: 420px;
    width: 100%;
    filter: drop-shadow(3px 2px 16px rgba(0, 0, 0, 0.2));
}

.home-page-link-certainty {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: #00ccff;
    padding: 10px 16px;
    border-radius: 6px;
    position: absolute;
    display: block;
    bottom: -162px;
    right: -116px;
}

.home-page-link-certainty:hover {
    background-color: #00beed;
    color: #fff;
}

.home-page-description-row-certainty {
    max-width: 205px;
    position: absolute;
    bottom: -116px;
    left: 234px;
    transform: rotate(-9deg);
}

@media screen and (max-width: 768px) {
    .home-page-certainty-container {
        position: unset;
    }

    .home-page-title-certainty-text {
        font-size: 32px;
    }

    .home-page-description-certainty-text {
        font-size: 14px;
    }

    .home-page-description-row-certainty {
        max-width: 145px;
        position: absolute;
        bottom: -86px;
        left: 74px;
        transform: rotate(-9deg);
    }

    .home-page-link-certainty {
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        background-color: #00ccff;
        padding: 10px 16px;
        border-radius: 6px;
        position: absolute;
        display: block;
        bottom: -129px;
        right: 107px;
    }

    .home-page-image-certainty-wrapper {
        display: none;
    }
}

.blue {
    background-color: #6ae0f2;
    color: #6ae0f2;
}

.container-howitworks {
    flex-direction: column;
}

.card-b {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.card-icon {
    align-items: baseline;
}
