.riquiredField {
    font-size: 11px;
    color: #f64c40;
    font-weight: 500;
}

.submitError {
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: #f64c40;
    font-weight: 500;
    margin-top: 10px;
}

.riquiredFieldSignIn {
    font-size: 11px;
    color: #f64c40;
    font-weight: 500;
    padding-right: calc(var(--geeks-gutter-x) * 0.5);
    padding-left: calc(var(--geeks-gutter-x) * 0.5);
}

.modal {
    position: absolute;
    z-index: 99999;
  }