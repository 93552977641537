// Theme Switch dark/light mode

.theme-switch {
    .form-check-input {
        background-color: transparent;
        border: none;
        cursor: pointer;
        margin: 0;
        padding: 18px;
        background-size: 22px;
        background-position: center;

        &:checked {
            background-size: 22px;
            background-position: center;
            background-color: transparent;
        }
        &:focus {
            box-shadow: none;
            background-size: 22px;
            background-position: center;
        }
    }
}
