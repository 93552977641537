.container-midia {
    width: 100%;
}

.wrapper-btn-midia {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    padding: 15px 12px;
    border-radius: 7px;
}

.wrapper-btn-midia:hover {
    background-color: #f1f5f9;
}

.image-midia {
    max-width: 20px;
}

.text-midia {
    margin-bottom: 0;
}

.carousel-midia {
    width: 100%;
    margin-top: 20px;
}

.container-video {
    width: 100%;
}

.wrapper-btn-video {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    padding: 13px 12px;
    border-radius: 7px;
}

.wrapper-btn-video:hover {
    background-color: #f1f5f9;
}

.image-video {
    max-width: 25px;
}

.text-video {
    margin-bottom: 0;
}

.error-created-image-posts {
    color: #cb2222;
    font-size: 14px;
}

.carousel-video {
    width: 100%;
    margin-top: 20px;
}

.carousel-item-midia-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    overflow: hidden;
}

@media screen and (max-width: 768px) {
    .carousel-item-midia-wrapper {
        height: 50vh;
    }
}

.carousel-item-image {
    max-width: 85%;
    max-height: 85%;
    width: auto;
    height: auto;
    object-fit: contain;
    
}

.carousel-item-midia-btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    font-size: 26px;
}

.carousel-item-video-btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    font-size: 26px;
}

.carousel-midia.carousel.slide.carousel-dark {
    background-color: #f1f5f9;
    width: 90%;
    border-radius: 8px;
    /* padding: 20px 0; */
    margin: 20px auto !important;
}

.carousel-control-prev {
    left: -38px !important;
    width: 39px !important;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #fff;
    opacity: 1;
}

.carousel-control-next {
    right: -38px !important;
    width: 39px !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    opacity: 1;
}

.carousel-indicators {
    bottom: -45px !important;
}
