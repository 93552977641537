.faq-item-container {
    background-color: #f1f5f9;
    padding: 25px;
    border-radius: .5rem;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 15px !important;
    border-left: 5px solid #00ccff;
}

.faq-item-container .answer-item-wrapper p:last-child {
    margin-bottom: 0 !important;
}

.faq-item-container .faq-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.faq-item-container .faq-title-wrapper .faq-icon-arrow-bottom {
    max-width: 14px;
    width: 100%;
}

.faq-item-container .faq-title-wrapper .faq-icon-arrow-top {
    max-width: 14px;
    width: 100%;
    rotate: 180deg;
}

.faq-image-wrapper {
    max-width: 600px;
}

.faq-image-wrapper .faq-image {
    width: 100%;
}

@media screen and (max-width: 1025px) {
    .faq-image-container {
        display: none;
    }

    .faq-item-card {
        padding-right: calc(var(--geeks-gutter-x)* 0.5) !important;
    }
}

@media screen and (max-width: 991px) {
    .faq-title-main-wrapper {
        margin-bottom: 2rem !important;
    }

    .faq-title-main-wrapper .faq-title-main {
        font-size: 2.2rem;
        margin-top: 20px;
    }
}

@media screen and (max-width: 768px) {
    .faq-title-main-wrapper {
        padding-right: 0 !important
    }

    .faq-title-wrapper h3 {
        font-size: 1.1rem;
    }
}