.top-notifications {
    position: absolute;
    top: 70px;
    right: 10px;
    padding: 10px;
    animation: aparecer 1s forwards; /* Nome da animação e duração */
}

@media screen and (max-width: 768px) {
    .top-notifications {
        padding: 40px;
        font-size: 20px;
        display: none;
    }
}

@media screen and (max-width: 1000px) {
    .top-notifications {
        padding: 40px;
        font-size: 20px;
        display: none;
    }
}

@keyframes aparecer {
    0% {
        opacity: 0;
        transform: translateY(100%); /* Começa fora da tela (100% da altura do elemento) */
    }
    100% {
        opacity: 1;
        transform: translateY(0); /* Termina na posição original (0% da altura do elemento) */
    }
}

.notification-toast {
    z-index: 0;
}
