.content-modal {
    position: fixed;
    z-index: 9999;
    top: 0;

    width: 100%;
    height: 100vh;

    background-color: #5c5c5c4b;

    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 770px;
    background-color: #f5f5f5;
    border-radius: 12px;
    transform: translate(-50%, -50%);
    max-height: 80vh; 
    height: auto;
    overflow-y: auto; 
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 70px;

    border-radius: 12px 12px 0 0;
    padding: 1rem 1.5rem;
    background-color: white;

    font-family: 'Inter';
    font-size: 20px;
    font-weight: 400;
}

.fe {
    cursor: pointer;
}

.text {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
}

.stronger {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 700;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    width: 100%;
    height: 100%;

    padding: 1.5rem;
}

.input {
    width:100%;
    height: 40px;
    padding: 10px 12px 10px 12px;
    border-radius: 9px;
    border: 1px solid #D1D0D0;
    margin-top: -0.5rem;
}

input:focus-visible {
    outline: none;
}

.section-button {
    width:100%;

    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
}

.button {
    width: 155px;
    height: 40px;
    padding: 0px 16px 0px 16px;
    border-radius: 9px;
    background: #00CCFF;
    border: none;

    color: white;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
}

.button-outlined {
    width: 145px;
    height: 40px;
    padding: 0px 16px 0px 16px;
    border-radius: 9px;
    background: #ffffff;
    border: 2px solid #00CCFF;

    color: #00CCFF;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
}

.field {
    display: flex;
    gap: 0.5rem;
    width: 350px;
}

.row {
    display: flex;
    flex-direction: row;
}

.error {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    color: #ff0000;
}

.divider {
    margin-top: -0.6rem;
    margin-bottom: -0.6rem;
}

.cards {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;

    width: 100%;
    padding: 15px 10px 10px 10px;

    border-radius: 10px;
    border: 1px solid #B5B5B5;
}

.icon {
    cursor: pointer;
    margin-top: 5px;
}

.boleto {
    width:100%;
    height: 165px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 10px;
    border: 1px solid #B5B5B5;
    background-color: white;
}

.image {
    width: 320px;
    height: 100px;
}

.outher-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    width: 100%;

    border-radius: 10px;
    border: 1px solid #B5B5B5;

    padding: 0 0 10px 0;
}

.outher {
    display: flex;
    gap: 0.5rem;

    width: 100%;
}

.outher-field {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    width: 100%;

    padding: 0 10px 0 10px;
}