.post-input {
    margin-left: 10px;
}

.image-container {
    position: relative;
    overflow: hidden;
}

.post-gallery {
    margin-left: 12px;
}

.post-header {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 12px;
}

.post-author-name h5 {
    margin-bottom: -4px !important;
}

.post-author-name small {
    margin-bottom: -6px !important;
    font-size: 12px;
}

.post-body p {
    font-size: 14px;
}

.post-actions {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center !important;
    gap: 2px;
}

.action-buttons {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 6px;
    border-radius: 8px;
    font-weight: bold;
    background-color: #fff;
    color: grey;
    border: none;
    cursor: pointer;
    width: 100%;
    justify-content: center;
}

.action-buttons:hover {
    background-color: #f1f5f9;
    color: #8c8c8c;
}

.action-footer {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

.post-input-comment {
    margin-left: 10px;
    width: 100%;
    max-width: -webkit-fill-available;
}

.send-comment {
    padding: 10px;
    cursor: pointer;
    font-size: 20px;
    border-radius: 8px;
    line-height: unset !important;
}

.send-comment:hover {
    background-color: #f2f2f2;
    color: #8c8c8c;
}

.comment-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.likes-and-comments {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* .likes-and-comments {
    cursor: pointer;
}

.likes-and-comments:hover {
    text-decoration: underline;
} */

.view-likes {
    display: flex;
    align-items: center;
    gap: 2px;
}

.view-likes p {
    margin: 0px !important;
}

.post-card {
    padding-bottom: 2px !important;
}

.unique-comment {
    display: flex;
    flex-direction: row;
    gap: 6px;
    margin-bottom: 8px;
    align-items: center;
}
.comment-avatar {
    align-self: baseline;
}

.unique-comment-comment {
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 14px;
    background-color: #f1f5f9;
    width: 100%;
}

.show-more-comments {
    margin-bottom: 8px;
}

.show-more-comments a {
    font-weight: bold;
    padding: 4px;
    border-radius: 8px;
    text-decoration: underline;
}

@media (max-width: 1199px) {
    .feed-profile-column {
        display: none;
    }
    .feed-providers {
        display: none;
    }
}

.edit-comment-dropdown {
    padding: 5px !important;
    margin: 2px !important;
    width: 1.5rem !important;
}

.comment-image {
    height: auto !important;
}

.remove-button {
    position: absolute;
    top: 5px; /* Ajusta a distância do topo conforme necessário */
    right: 5px; /* Ajusta a distância da direita conforme necessário */
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.react-video-player {
    object-fit: contain;
    max-width: 100%;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2px;
    padding: 0px;
}

.grid-item {
    position: relative;
    overflow: hidden;
    border-radius: 1px;
}

.grid-item img {
    width: 100%;
    height: auto;
    display: block;
}

.grid-item .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 24px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.grid-item:hover .overlay {
    opacity: 1;
}

@media (max-width: 390px) {
    .react-grid {
        width: 370px !important;
        max-width: 320px !important;
    }
}

.recommendation-user-occupation-area,
.recommendation-user-city {
    color: rgb(100, 116, 139);
}

.recommendation-user-link:hover .recommendation-user-namer{
    text-decoration: underline;
    text-decoration-color: #1e293b;
    color: #1e293b;
    opacity: .8;
}
