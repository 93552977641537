.edit_image_profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.edit_image_profile p {
    display: flex;
    flex-direction: column;
    color: green;
}

.edit_image_profile_buttons {
    justify-content: end;
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.password_strength p {
    text-align: left !important;
}

.skills-badge {
    margin-right: 5px !important;
    margin-bottom: 5px !important;
    color: white !important;
}

.skills-subtitle {
    padding: 5px;
    margin-bottom: 1px !important;
}

.process-ratings {
    margin-bottom: 10px;
}

.my-requests-user-wrapper {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    border-bottom: var(--geeks-card-border-width) solid var(--geeks-card-border-color);
}

.my-requests-information-wrapper {
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 6px;
    background-color: #f1f5f9;
}

.my-requestes-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 20px;
}

.my-requests-user-image {
    width: 80px;
    height: 80px;
    padding-left: 0;
    border-radius: 50%;
}

.my-requests-col {
    display: flex;
    flex-direction: column;
    padding: 0;
}

.my-requests-col-image {
    margin-right: 30px;
}

.my-requests-urgency-low {
    width: fit-content;
    padding: 3px 8px;
    border-radius: 6px;
    color: #fff;
    background-color: #38a169;
    font-size: 12px;
}

.my-requests-urgency-mid {
    width: fit-content;
    padding: 3px 8px;
    border-radius: 6px;
    background-color: #f59e0b;
    color: #fff;
    font-size: 12px;
}

.my-requests-urgency-high {
    width: fit-content;
    padding: 3px 8px;
    border-radius: 6px;
    color: #fff;
    background-color: #e54a36;
    font-size: 12px;
}

.my-requests-card-body {
    padding-bottom: 0;
}

.my-requests-information-space {
    padding: 0;
    max-width: 80px;
    margin-right: 30px;
}

.my-requests-information-item {
    padding: 0;
}

.my-requests-user-information-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1200px) {
    .my-requestes-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .my-requests-user-image {
        width: 80px;
        height: 80px;
    }

    .my-requests-user-wrapper {
        flex-direction: row;
        text-align: center;
        gap: 15px;
    }

    .my-requests-information-wrapper {
        display: none;
    }

    .my-requests-col-image {
        margin-right: 0;
    }

    .my-requests-user-information-wrapper {
        text-align: start;
        align-items: start;
        flex-direction: column;
        gap: 5px;
    }

    .my-requests-card-body {
        padding-top: 0;
    }

    .my-requests-user-name,
    .my-requests-user-job,
    .my-requests-user-email,
    .my-requests-user-data-job {
        font-size: 12px;
    }

    .my-requests-urgency-low,
    .my-requests-urgency-mid,
    .my-requests-urgency-high {
        font-size: 12px;
        padding: 1px 6px;
    }
}

@media screen and (max-width: 992px) {
    .my-requestes-container {
        grid-template-columns: repeat(1, 1fr);
    }
}

/* NOTIFICATION STYLE */
.my-notification-container {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 992px) {
    .my-notification-container {
        grid-template-columns: repeat(1, 1fr);
    }
}

.my-notification-user-wrapper {
    display: flex;
    align-items: center;
    background-color: #f1f5f9;
    padding: 20px;
    gap: 20px;
    border-radius: 5px;
}

.my-notification-user-wrapper:hover {
    cursor: pointer;

    .my-notification-message {
        color: #00ccff;
    }
}

.my-notification-image {
    max-width: 75px;
    width: 100%;
}

.my-notification-message {
    color: #64748b;
}

.my-notification-col {
    gap: 5px;
}

.my-requests-user-menu {
    max-width: 20px;
    width: 100%;
}

.my-requests-user-menu-wrapper {
    display: block;
    padding: 8px 10px;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.2s;
    position: relative;
}

.my-requests-user-menu-wrapper:hover {
    background-color: #f1f5f9;
    transition: 0.2s;
}

.my-requests-information-space-two {
    max-width: 40px;
    width: 100%;
}

.popup-my-requests {
    position: absolute;
    top: 40px;
    left: -33px;
    min-width: fit-content;
}

.my-requests-user-menu-wrapper-mobile {
    display: none;
}

@media screen and (max-width: 1200px) {
    .my-requests-user-menu-wrapper-mobile {
        display: block;
        position: relative;
        min-width: fit-content;
    }

    .popup-my-requests {
        top: 30px;
        left: -53px;
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .popup-my-requests .dropdown-item {
        font-size: 12px !important;
    }

    .my-requests-user-menu-wrapper {
        display: none;
    }
}

.image-container {
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    width: 100%;
    height: 100%;
}

.remove-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: white !important;
    color: white !important;
    border: none;
    padding: 5px;
    cursor: pointer;
    font-size: 12px;
    border-radius: 50%; /* Adicionando borda circular */
    width: 20px; /* Largura fixa para garantir um círculo */
    height: 20px; /* Altura fixa para garantir um círculo */
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.image-grid-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.image-grid-wrapper .image-wrapper img {
    width: 100%;
    height: 190px;
    object-fit: cover;
    transition: transform 0.3s ease;
}

@media screen and (max-width: 991px) {
    .image-grid-wrapper {
        padding-bottom: 10px;
    }

    .image-grid-wrapper .image-wrapper {
        min-width: 150px;
        min-height: 150px;
    }

    .image-grid-wrapper .image-wrapper img {
        height: 150px;
    }

    .image-grid-wrapper {
        display: flex;
        flex-wrap: nowrap;
        overflow: scroll;
    }
}

.image-grid-wrapper .image-wrapper img:hover {
    transform: scale(1.05);
}

.modal-image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.service-table {
    overflow-x: auto;
    white-space: nowrap; /* Isso impede que o texto quebre em várias linhas */
}

.feed-user-card {
    border-radius: 8px;
    background-color: #00ccff;
    padding: 10px;
}

.feed-card-header {
    padding: 0px !important;
    width: 100%;
}

.feed-avatar {
    border: 2px solid #f1f5f9;
}

.feed-profile-column {
    width: 20%;
}

.user-details-small {
    margin-left: 4px;
}

.info-popover {
    background-color: white;
    border: none;
    padding: 0px;
}

.info-popover:hover {
    background-color: white;
    border: none;
    padding: 0px;
}

.col-service-wrapper {
    padding: 0 25px;
}

@media screen and (max-width: 768px) {
    .col-service-wrapper {
        padding-left: 12px;
        padding-right: 0;
    }
}

.col-service-wrapper .table-responsive .table {
    margin-bottom: 95px;
}

.edit-porfile-card {
    max-width: 967px;
    width: 100%;
}

@media screen and (max-width: 1399px) {
    .edit-porfile-card {
        max-width: 830px;
    }
}

@media screen and (max-width: 1199px) {
    .edit-porfile-card {
        max-width: 696px;
    }
}

@media screen and (max-width: 991px) {
    .edit-porfile-card {
        max-width: 683px;
    }
}

.button-image-rearrange-wrapper {
    display: flex;
    margin-bottom: 20px;
}

.droppable-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-height: 600px;
    padding-right: 3px;
    overflow-y: auto;
}

.droppable-container::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #f4f4f4;
}
.droppable-container::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px;
    background: #f4f4f4;
}
.droppable-container::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #d6dbe1;
}

.droppable-container .image-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px 5px 5px;
    max-height: 70px;
    min-height: 60px;
    background-color: rgb(241, 245, 249);
    border: solid 1px #d6dbe1;
}

.droppable-container .image-container img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 5px;
    transition: transform 0.3s ease;
}

.droppable-container .image-container .icon-field-drag {
    max-width: 10px;
    max-height: 20px;
    width: 100%;
    object-fit: contain;
}

.image-grid-container .message-droppable-wrapper {
    display: flex;
    gap: 5px;
    margin-bottom: 20px;
}

.image-grid-container .message-droppable-wrapper .message-droppable {
    font-size: 14px;
}

.image-grid-container .message-droppable-wrapper .droppable-icon {
    max-width: 23px;
    max-height: 23px;
    width: 100%;
}

.modal-footer-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-footer-wrapper .message-success-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 7px 12px;
    border-radius: 6px;
    background-color: #b7ddc4;
    border-left: 5px solid #329532;
}

.modal-footer-wrapper .message-success-wrapper .message-success-images {
    font-size: 14px;
    color: #393939;
}

.modal-footer-wrapper .message-success-wrapper .message-success-icon {
    max-width: 20px;
}

.modal-footer-wrapper .message-error-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 7px 12px;
    border-radius: 6px;
    background-color: #fee2df;
    border-left: 5px solid #ee4235;
}

.modal-footer-wrapper .message-error-wrapper .message-error-images {
    font-size: 14px;
    color: #393939;
}

.modal-footer-wrapper .message-error-wrapper .message-error-icon {
    max-width: 20px;
}

.my-portfolio-children-wrapper .card-pix-container,
.my-portfolio-children-wrapper .card-value-container {
    width: 49%;
    background-color: #f1f5f9;
    padding: 20px;
    border-radius: 10px;
    margin: 0;
}

.card-pix-container .card-pix-title-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0;
    margin-bottom: 5px;
}

.my-portfolio-children-wrapper .card-pix-title {
    padding: 0 !important;
    font-size: 1.3rem;
}

.my-portfolio-children-wrapper .card-pix-sub-title {
    padding: 0 !important;
}

.my-portfolio-children-wrapper .card-pix-key-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
}

.my-portfolio-children-wrapper .card-pix-icon {
    max-width: 40px;
    width: 100%;
    background-color: #011c25;
    border-radius: 50%;
    padding: 5px;
}

.my-portfolio-children-wrapper .card-pix-key-wrapper .card-pix-key {
    width: 100%;
    color: #000;
    min-height: 40px;
    padding: 7px 5px;
    padding-left: 40px;
    border-radius: 5px;
    background-color: #fff;
    border: solid 1px #cbd5e1;
}

.my-portfolio-children-wrapper .card-pix-key-wrapper .card-pix-key-icon {
    max-width: 18px;
    position: absolute;
    left: 10px;
}

.my-portfolio-children-wrapper .card-pix-button {
    width: 100%;
    margin-top: 1rem;
    background-color: #01c5f6;
}

.card-value-container h3 {
    color: #000;
    font-size: 1.3rem;
}

.card-value-container h2 {
    font-size: 2rem;
    color: #000 !important;
}

.card-value-container .card-value-sub-title {
    padding: 0 !important;
    font-weight: 400 !important;
}

@media screen and (max-width: 1200px) {
    .card-value-and-pix-wrapper {
        flex-direction: column !important;
    }

    .card-value-container {
        width: 100% !important;
        margin-bottom: 20px !important;
    }

    .card-pix-container {
        width: 100% !important;
    }
}

.icone-confirmacao {
    width: 100%;
    max-height: 150px;
    height: 100%;
    color: #00ccff;
}
