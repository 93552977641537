.portfolio-container {
    display: flex;
    flex-wrap: nowrap;
}

.my-portfolio-children-wrapper {
    flex-shrink: unset;
}

@media screen and (max-width: 991px) {
    .portfolio-menu-container {
        width: 30% !important;
    }
}

@media screen and (max-width: 768px) {
    .my-portfolio-children-wrapper {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .portfolio-container {
        flex-wrap: wrap;
    }

    .portfolio-menu-container {
        padding: 0;
        width: 100% !important;
    }
}

.my-portfolio-children-col {
    width: 100% !important;
}