.chat-box {
    position: fixed;
    bottom: 0;
    right: 450px;
    width: 400px;
    z-index: 10;

    background-color: #27d4ff;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    color: white;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

@media (max-width: 767px) {
    .chat-box {
        position: fixed;
        bottom: 0;
        right: 50%;
        width: 90%;
        margin-right: 0;
        transform: translate(50%, 0%);
        background-color: #27d4ff;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
        color: white;
    }
}

.chat-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    color: white;
    padding: 10px;
    justify-content: space-between;
}

.chat-header i:hover {
    background-color: rgba(200, 200, 200, 0.7);
    border-radius: 50%;
}

.chat-header h4 {
    color: white !important;
}

.header-title small {
    margin-top: -5px;
}

.chat-messages {
    height: 400px;
    background-color: white !important;
    padding: 10px;
    color: black;
}

.chat-bottom-footer {
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 6px;
}

.chat-bottom-footer i {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.right {
    text-align: end;
    justify-content: end;
    display: flex;
    flex-direction: column;
}

.left {
    text-align: start;
    align-self: start;
    display: flex;
    flex-direction: column;
}

.chat-divider {
    align-self: center;
    font-weight: bold;
    margin-bottom: 0.5rem;
}
.message-description-right {
    margin-bottom: 10px;
    width: fit-content;
    align-self: flex-end;
    text-align: justify;
}

.message-description-left {
    margin-bottom: 10px;
    width: fit-content;
    align-self: flex-start;
    text-align: justify;
    display: flex;
    align-items: center;
    gap: 6px;
}

.message-description-left svg {
    font-size: 18px;
    cursor: pointer;
    color: #404040;
}

.message-description-left small {
    font-size: 10px !important;
}

.message-description-right small {
    font-size: 10px !important;
}

.message-description-right p {
    background-color: #27d4ff;
    padding: 10px;
    border-radius: 12px;
    color: white;
    display: flex;
    flex-direction: column;
    margin-bottom: 0px !important;
}

.message-description-left p {
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    margin-bottom: 0px !important;
}

.message-description-right a {
    background-color: #27d4ff;
    padding: 10px;
    border-radius: 12px;
    color: white;
    display: flex;
    font-weight: bold;
    font-style: italic;
    flex-direction: column;
    margin-bottom: 0px !important;
}

.message-description-left a {
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 12px;
    display: flex;
    font-weight: bold;
    font-style: italic;
    flex-direction: column;
    margin-bottom: 0px !important;
}

.chat-body {
    flex: 1;
    overflow-y: auto; /* Adicione uma barra de rolagem vertical se necessário */
    background-color: white;
}

/* Mensagens.css */

.mensagens-header {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    background-color: #27d4ff;
    align-items: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

/* Estilos para o contêiner principal */
.mensagens-container {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 400px;
    z-index: 10;
    margin-right: 30px;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    color: white;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    max-height: 600px;
    scrollbar-width: thin; /* largura da barra de rolagem */
}

@media screen and (max-width: 768px) {
    .mensagens-container {
        right: 50%;
        width: 90%;
        margin-right: 0;
        transform: translate(50%, 0%);
    }
}

.my-messages-left {
    cursor: pointer;
    /* Estilos básicos para o cabeçalho */

    font-size: bold;
    color: white !important;
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    background-color: #27d4ff;
}

.my-messages-left h4 {
    color: white;
    margin-bottom: 0px;
}
.mensagens-container.expanded {
    height: 300px;
}

.mensagens-lista {
    height: calc(100vh - 130px);
    color: black;
    overflow-y: auto;
    scrollbar-width: thin; /* largura da barra de rolagem */
    scrollbar-color: black;
}

/* @media screen and (max-width: 768px) {
    .mensagens-lista {
        max-height: 700px;
    }
}

@media screen and (max-width: 375px) {
    .mensagens-lista {
        max-height: 530px;
    }
} */

.mensagem-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    width: 100%;
    max-width: 100%;
}

.mensagem-conteudo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
}

.chat-description {
    display: flex;
    flex-direction: column;
    gap: 0px !important;
}

.chat-description small {
    width: 180px; /* largura definida para fins de exemplo */
    white-space: nowrap; /* impede que o texto quebre em várias linhas */
    overflow: hidden; /* oculta o texto que ultrapassa a largura do contêiner */
    text-overflow: ellipsis;
}

.chat-portfolioTitle {
    font-size: 12px;
}

.chat-description h4 {
    margin-bottom: 0px !important;
}

.file:hover {
    text-decoration: underline;
}

.file {
    font-size: 14px;
}

.chat-subheader {
    display: flex !important;
    width: 100%;
    padding: 0xp !important;
    gap: 0px !important;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.chat-subheader button {
    background-color: #404040;
    color: white;
    padding: 6px !important;
    display: flex;
    gap: 4px;
    align-items: center;
    border-radius: 0px;
    width: 100%;
    margin: 0px !important;
    border: 0px !important;
    justify-content: center;
}

.chat-subheader button:hover {
    background-color: rgb(230, 230, 230);
    color: rgb(1, 28, 37);
}

.chat-fast-service {
    padding: 20px;
    color: #404040;
}

.fast-service-description {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 10px;
}

.fast-service-description span {
    font-size: 14px;
}

.fast-service-form {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.fast-service-title {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
}

.nova-mensagem {
    background-color: rgba(39, 212, 255, 0.15);
}

.chat-divider {
    align-self: center;
}

.sininho {
    position: relative;
}

.sininho-bell {
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    animation: ring 1s infinite;
}

.sininho-bell svg {
    font-size: 14px;
    animation: ring 0.5s infinite;
}

.sininho-clapper {
    width: 8px;
    height: 8px;
    background-color: #27d4ff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

@keyframes ring {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
