#logo {
    width: 76px;
    align-items: center;
    text-align: center;
    justify-content: center;
}

#logo-grande {
    width: 200px;
    align-items: center;
    text-align: center;
    justify-content: center;
}

#div-logo {
    display: flex !important;
    flex-direction: column;
    align-items: center !important;
    text-align: center;
    justify-content: center !important;
}

#div-logo-grande {
    display: flex !important;
    flex-direction: column;
    align-items: center !important;
    text-align: center;
    justify-content: center !important;
}

.signin {
    max-width: 500px;
}

.custom-spinner {
    width: 50%;
}

.errorMessageInput {
    margin-left: 3px !important;
    margin-bottom: 0px !important;
}

.select-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background-color: #00ccff;
    padding: 18px;
    border-radius: 8px;
    cursor: pointer;
}

.select-card-title {
    text-align: center;
    color: white;
    padding: 0px !important;
    margin: 0px !important;
    font-size: 1rem !important;
}

.select-card-icon {
    color: white;
}

.hidden {
    display: none;
}
