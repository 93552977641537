.scroll_description {
    height: 145px;
    overflow-y: scroll;
    margin: 10px;
    padding-right: 10px;
    width: 100%;
}

.scroll_description::-webkit-scrollbar {
    width: 5px;
}

.scroll_description::-webkit-scrollbar-track {
    padding: 2px 0;
    border-radius: 5px;
    background-color: #e0e0e0;
}

.scroll_description::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #00ccff;
}

.card_wrapper {
    margin-left: 0px !important;
}

.div-resume {
    margin-top: 8px;
}
