.service-container {
    max-width: 1600px !important;
}
.page_title {
    align-items: center;
    text-align: center;
    flex-direction: row;
}
.page_title h4 {
    color: white;
    padding: 5px;
    margin: 0px !important;
}
.loadingResults {
    align-items: center;
    text-align: center;
}

#spinner_wrapper {
    margin-bottom: 30px;
}

.job-description p {
    overflow-wrap: anywhere;
}

.job-resume {
    text-transform: capitalize;
}

.owner-description {
    word-wrap: break-word;
}

.favorite-job:hover {
    display: none;
}

.proposal-modal {
    display: grid;
    gap: 5px;
}

.card-header {
    display: flex;
    justify-content: space-between;
}

.profile-body {
    display: flex;
    justify-content: space-between;
}

.profile-resume {
    max-height: 100px;
    overflow: hidden;
}

.profile-resume.expandir {
    max-height: none;
    overflow: visible;
}

.profile-resume p.ler-mais {
    display: none;
}

.social-buttons {
    display: flex;
    justify-content: flex-end;
}

.contact-buttons button {
    margin-right: 3px;
}

.card-profile {
    width: 100%;
    max-width: 400px;
    margin-right: 15px;
    margin-bottom: 15px;
}

.tabpane .card-profile {
    display: flex;
    flex-wrap: wrap;
}

.portfolioTitle {
    max-width: 200px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-line-clamp: 2;
}

@media (max-width: 768px) {
    .provider-picture {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .provider-description {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        flex-direction: column !important;
        text-align: center;
    }

    .card-profile-body {
        justify-content: start;
        align-items: center;
        gap: 20px;
    }

    .card-profile-person-name {
        display: flex;
        justify-content: start;
        align-items: center;
        text-align: center;
    }

    .portfolioTitle {
        text-align: start;
    }

    .profile-title {
        justify-content: center;
    }

    .profile-description {
        margin: 0px !important;
        justify-content: center;
        flex-direction: column;
    }

    .desc {
        justify-content: center;
        flex-direction: column;
    }

    .profile-email {
        justify-content: center;
    }

    .profile-state {
        justify-content: start;
    }

    .profile-skills {
        justify-content: center;
    }

    .social-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .media-buttons {
        margin: 0px !important;
        padding: 0px !important;
    }

    .profile-action-buttons {
        margin-bottom: 10px;
    }

    .card-profile {
        margin-bottom: 15px;
        margin-right: 0px !important;
    }

    .service-filters {
        justify-content: center;
        max-width: 420px !important;
        width: 420px !important;
        padding: 0px !important;
    }

    .lista-prestadores-wrapper {
        padding: 0 0.7rem;
    }

    .lista-prestadores {
        max-width: 400px !important;
    }

    .teste {
        max-width: 400px !important;
    }

    .paginador {
        max-width: 100%;
        width: 380px !important;
        font-size: 12px;
        margin: 0px !important;
    }
}

@media screen and (max-width: 380px) {
    .portfolioTitle {
        max-width: 160px !important
    }

    .lista-prestadores-wrapper {
        padding: 0;
    }
}

.m-header-background {
    background-color: #00ccff;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    justify-content: center !important;
    position: relative;
    height: 100px;
    width: 100%;
    flex-direction: column;
    border-top: 1px !important;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.modal-content {
    display: block !important;
    border: none !important;
    background-color: white !important;
    border-top: 1px !important;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.modal-total {
    border-top: 1px !important;
    border-radius: 8px !important;
}

.m-modal {
    display: block;
    border-top: 1px !important;
    border-radius: 8px !important;
}

.m-header {
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    justify-content: center !important;
    z-index: 1;
    flex-direction: column;
    border: none !important;
    border-radius: 0 !important;
    margin-bottom: -30px;
    border-top: 1px !important;
    border-radius: 8px !important;
}

.m-title {
    position: sticky;
    left: 50%;
    transform: translate(-50%, -20%);
    z-index: 1;
}

.m-title div {
    margin-bottom: 5px;
}

.m-profile-description {
    margin-bottom: 4px !important;
}

.followers {
    color: #00ccff;
}

.modal-total {
    display: block;
    flex-direction: column;
}

.m-modal {
    display: flex;
    gap: 1;
    flex-wrap: wrap;
}

.m-body {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    border-top: 1px !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 5px;
    padding-bottom: 20px;
    width: 100%;
}

.styled-hr {
    border: none;
    height: 2px; /* Ajuste a altura desejada */
    width: 90%; /* Ajuste a largura desejada */
    background-color: #ccc; /* Cor do traço horizontal */
    margin: 0px 0px 20px; /* Espaçamento acima e abaixo do hr */
}

.profile-resume-md {
    width: 90%;
    display: flex;
    flex-direction: column;
}

.profile-info {
    width: 90%;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.profile-resume h5 {
    margin-bottom: 1px !important;
}

.title-styled-hr {
    padding: 1.5px;
    border: 5px #00ccff;
    height: 2px; /* Ajuste a altura desejada */
    width: 60px; /* Ajuste a largura desejada */
    background-color: #00ccff; /* Cor do traço horizontal */
    margin: 0px 0; /* Espaçamento acima e abaixo do hr */
}

.profile-resume-box p {
    display: flex;
    margin-top: 10px;
    background-color: #f6f6f6;
    max-width: 100%;
    padding: 6px;
}

.follow-button:hover {
    background-color: #011f26 !important;
    color: white !important;
    border-color: #011f26;
}

.following {
    background-color: #011f26 !important;
    color: white !important;
    border-color: #011f26;
}

.follow {
    background-color: #00ccff !important;
    color: white !important;
    border: none;
}

.image-profile {
    border-radius: 50% !important;
    border: solid 2px #fff !important;
}

.btn-solicitar-service {
    margin-bottom: 5rem;
}

@media (max-width: 600px) {
    .profile-info {
        flex-direction: column;
    }
}

.services-list:first-child {
    margin-top: 16px !important;
}

.services-list {
    margin-bottom: 1px !important;
    margin-top: 2px !important;
    border-left: 5px solid #00ccff;
}

.modal-request {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 5px;
    z-index: 10000;
}

.image-grid-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.image-grid-list img {
    max-height: 200px;
    height: 100%;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.image-grid-list img:hover {
    transform: scale(1.05);
}

@media screen and (max-width: 991px) {
    .image-grid-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.image-grid img {
    max-height: 200px;
    height: 100%;
    width: 100%;
    max-width: 100%;
    object-fit: fill;
    transition: transform 0.3s ease;
}

.image-grid img:hover {
    transform: scale(1.05);
}

@media screen and (max-width: 768px) {
    .image-grid {
        grid-template-columns: 1fr;
    }

    .provider-badge {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: #007bff;
        color: #fff;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: bold;
    }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
    .provider-badge {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: #007bff;
        color: #fff;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: bold;
    }
}

.serviceList {
    list-style: none;
    padding-left: 0;
}

.ratingCards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.rating {
    margin-bottom: 30px;
    padding-left: 20px;
    border-left: 5px solid #00ccff;
}

.ratingTitle {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 12px;
}

.evaluator {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.required {
    font-weight: bold;
    color: red;
    margin-top: 5px;
}

.success {
    margin-top: 10px;
}

.ratingDate {
    margin: 0px !important;
    font-size: 14px;
}

.ratingValue {
    font-size: 14px;
    font-weight: bold;
}

.ratingSubtitle {
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: row;
}

.btn-action {
    height: 30px;
}

.unfollow:hover {
    background-color: #00ccff;
    border-color: #00ccff;
}

.card-with-shadow {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.service-description {
    margin: 0px !important;
    margin-left: 4px !important;
}

.userType-provider {
    border: #00ccff solid 3px;
}

.provider-badge {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #007bff; /* Cor de fundo do selo */
    color: #fff; /* Cor do texto do selo */
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
}

.provider-picture {
    position: relative;
}

.modal-pictures-body {
    background-color: rgba(100, 0, 0, 0);
}

.logo-prestadio {
    align-self: center;
    width: 250px;
}

.action-button {
    max-height: 40px;
}
sis .message:hover {
    color: white;
}

.provider-header {
    max-width: 100%;
    margin-top: 2rem;
    border-radius: 10px;
    border-bottom: 1px solid #f2f2f2;
    padding-top: 0px;
    padding-bottom: 20px;
    padding-left: 0px;
    padding-right: 0px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1.95px 0px;
}

.prestadio-background {
    width: 100%;
    margin-bottom: 2rem;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.prestadio-background img {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
}

.provider-info {
    padding-left: 2rem;
    padding-right: 2rem;
}

.locations {
    max-height: 396px;
    overflow-y: auto;
}

.cardzao {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
}

.carousel-indicators li {
    background-color: #ff0000 !important;
}

.carousel-indicators .active {
    background-color: #007bff !important;
}

.carousel-control-next-icon {
    fill: #ff0000 !important;
}

.carousel-control-next {
    color: #ff0000 !important;
}

.carousel-control-next:hover,
.carousel-control-prev:hover {
    color: #00ff00 !important;
}

.userType-prestadioTeam {
    border: 3px solid black;
}
